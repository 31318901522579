import * as React from 'react';
import ClassNames from 'classnames';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';

import { Constants, StM, SrvM } from '../../../../modules';

interface ILessonTabLinkProps {
    routeParams: StM.IBookPageRouteParams;
    coaches: StM.ICoachStoreState[];
    coachFeeTiers: StM.ICoachFeeTierStoreState[];
    availableTimesLookup: IDictionary;
    club: StM.IClubStoreState;

    onTabClickHandler: (params: any) => void;
}

interface ILessonTabLinkState {
    isShown?: boolean;
    orderedCoaches: StM.ICoachStoreState[];
    coachCount: number;
}

export class LessonTabLink extends React.Component<ILessonTabLinkProps, ILessonTabLinkState> {
    private out: number = 78;
    private coachHeight: number = 95;
    private utils = new SrvM.Utils();
    private routeSrv = new SrvM.RouteService();

    constructor(props: any) {
        super(props);
        this.state = { 
            isShown: props.routeParams.sessionType === StM.BookPageSessionType.Lesson, 
            orderedCoaches: [], 
            coachCount: 0 
        };
    }

    public componentDidMount() {
        this.init();
    }

    public componentDidUpdate(prevProps: ILessonTabLinkProps) {
        if (this.props.routeParams.lessonSubfilter !== prevProps.routeParams.lessonSubfilter || 
            this.props.coaches.length !== prevProps.coaches.length || 
            this.props.routeParams.date !== prevProps.routeParams.date || 
            !_.isEqual(this.props.availableTimesLookup, prevProps.availableTimesLookup)) {
            this.init(this.props);
        }
    }

    public render() {
        const coaches = this.state.orderedCoaches;
        const coachCount = this.state.coachCount;
        const lessonSubfilterId = this.props.routeParams.lessonSubfilterId;
        const isActive = this.props.routeParams.sessionType === StM.BookPageSessionType.Lesson;
        const classes = ClassNames({
            "side-block-item-wrapper": true,
            "side-block-item-wrapper-lesson": true,
            "session": true,
            "extended": isActive,
            'open': this.state.isShown 
        });

        const activeCoaches = coaches.length ? this.getCoachesFilter(coaches, lessonSubfilterId, true) : null;
        const inactiveCoaches = coaches.length ? this.getCoachesFilter(coaches, lessonSubfilterId, false) : null;
        const coachAvailabilityPixelOffset = 
            (activeCoaches?.props?.children && inactiveCoaches?.props?.children) ? 88 : 
            (activeCoaches?.props?.children || inactiveCoaches?.props?.children) ? 44 : 0;

        const setHeightOuter = (elem: any) => {
            const blockLessonItem: any = document.getElementsByClassName('side-block-item-lesson')[0];
            const blockLessonItemOutter: any = document.getElementsByClassName('side-block-item-wrapper-lesson')[0];

            if (this.state.isShown) {
                blockLessonItem.style.height = 74 * coachCount + this.out + 'px';
                blockLessonItemOutter.style.height = 74 * coachCount + this.out + 'px';
                const blockLessonHidden: any = document.getElementsByClassName('lesson-hidden-content')[0];

                if (this.utils.get_name_browser() !== "Safari") {
                    blockLessonItem.style.height = this.coachHeight * coachCount + this.out + 'px';
                    blockLessonItemOutter.style.height = this.coachHeight * coachCount + this.out + coachAvailabilityPixelOffset + 'px';
                } else {
                    blockLessonItem.style.height = this.coachHeight * coachCount + this.out - 9 + 'px';
                    blockLessonItemOutter.style.height = this.coachHeight * coachCount + this.out - 9 + 'px';
                }
            } else {
                blockLessonItem.style.height = '82px';
                setTimeout(() => this.setBlockLessonHeight(), 250);
                blockLessonItemOutter.style.height = '82px';
                setTimeout(() => this.setOpacity(), 370);
            }
        }

        return (
            <div ref={(elem) => setHeightOuter(elem)} className={classes}>
                <div className="side-block-item side-block-item-lesson">
                    <div className="block-item" >
                        <Link to={this.getRoute()} className="block-item-title" onClick={(e) => this.onTabClick(e)}>LESSON</Link>
                        <div className="hidden-content lesson-hidden-content">
                            {activeCoaches}
                            {inactiveCoaches}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private getCoachesFilter(
        coaches: StM.ICoachStoreState[],
        currentCoachId: string,
        showActiveOnly: boolean
    ) {
        const date = this.props.routeParams.date === 'today' 
            ? this.utils.getCurrentClubDateTime(this.props.club).format(Constants.DateTime.DATE_FORMAT) 
            : this.props.routeParams.date;

        const filteredCoaches = coaches.filter((coach: StM.ICoachStoreState) => {
            const availableTime = this.props.availableTimesLookup 
                && this.props.availableTimesLookup[coach.id] 
                && this.props.availableTimesLookup[coach.id][date]
                ? this.props.availableTimesLookup[coach.id][date]
                : 0;

            return showActiveOnly ? availableTime > 0 : availableTime === 0;
        });
            
        return (
            <section>
                {filteredCoaches.length > 0 ? 
                    <>
                        <div className='coach-divider'>
                            {showActiveOnly ? 'Available' : 'Unavailable'} coaches
                        </div>
                        {filteredCoaches.map((coach: StM.ICoachStoreState) => {
                            const isActive = coach.id === currentCoachId;
                            const classes = ClassNames('inner-item-wrapper', { active: isActive });
                            const displayTier = coach.coachFeeTier ? coach.coachFeeTier.title : 'no tier';
                            const image = coach.imageId ? coach.imageId : '';
                            const urlimg = image ? `/api/v2/blobs/${image}/content` : '/content/img/nophoto-2.png';
        
                            return (
                                <Link to={this.getRoute(coach.id)} className={classes} key={coach.id} onClick={(e) => this.onCoachClick(e, coach)}>
                                    <div className="user-avatar-wrapper">
                                        <img className={`user-avatar ${showActiveOnly ? '' : 'unavailable-coach-avatar'}`} src={urlimg} />
                                    </div>
                                    <div className="user-characteristic-wrapper">
                                        <div className={`name ${showActiveOnly ? '' : 'unavailable-coach-text'}`}>{coach.displayName}</div>
                                        <div className="name-coach-tooltip">
                                            <span className="name-coach-tooltip-title">{coach.displayName}</span>
                                        </div>
                                        <div className={`tier ${showActiveOnly ? '' : 'unavailable-coach-text'}`}>{displayTier}</div>
                                    </div>
                                </Link>
                            );
                        })}
                    </> : null
                }
            </section>
        );
    }

    private init(props: ILessonTabLinkProps = this.props) {
        const { coaches, coachFeeTiers, availableTimesLookup, routeParams } = props;
        const orderedCoaches = this.utils.getOrderedCoachesDesktop(
            coaches,
            coachFeeTiers,
            availableTimesLookup,
            routeParams.date === 'today' ? this.utils.getCurrentClubDateTime(this.props.club).format(Constants.DateTime.DATE_FORMAT) : routeParams.date
        );
        this.setState({
            isShown: routeParams.sessionType === StM.BookPageSessionType.Lesson,
            orderedCoaches: orderedCoaches,
            coachCount: orderedCoaches.length
        });
    }

    private setOpacity() {
        const blockLessonHidden: any = document.getElementsByClassName('lesson-hidden-content')[0];
        if (blockLessonHidden) {
            blockLessonHidden.style.opacity = '1';
        }
    }

    private setBlockLessonHeight() {
        const blockLessonItem: any = document.getElementsByClassName('side-block-item-lesson')[0];
        if (blockLessonItem) {
            blockLessonItem.style.height = '81px';
        }
    }

    private onTabClick(e: any): void {
        if (e) { e.stopPropagation(); e.preventDefault(); }

        const coachCount = this.state.coachCount;
        if (this.props.routeParams.sessionType === StM.BookPageSessionType.Lesson) {
            this.setState({ isShown: !this.state.isShown });
        }
        this.props.onTabClickHandler({ sessionType: StM.BookPageSessionType.Lesson, date: this.props.routeParams.date });

        const blockLessonItem: any = document.getElementsByClassName('side-block-item-lesson')[0];
        const blockLessonItemOutter: any = document.getElementsByClassName('side-block-item-wrapper-lesson')[0];

        const blockLessonHidden: any = document.getElementsByClassName('lesson-hidden-content')[0];

        blockLessonHidden.style.opacity = '0';
        setTimeout(() => this.setOpacity(), 370);

        if (!this.state.isShown) {
            if (navigator.userAgent.indexOf("Safari") !== -1 || !(navigator.userAgent.indexOf('MSIE') !== -1)) {
                blockLessonItem.style.height = this.coachHeight * coachCount + this.out + 'px';
                blockLessonItemOutter.style.height = this.coachHeight * coachCount + this.out + 'px';
            } else {
                blockLessonItem.style.height = this.coachHeight * coachCount + this.out - 26 + 'px';
                blockLessonItemOutter.style.height = this.coachHeight * coachCount + this.out - 26 + 'px';
            }
        } else {
            blockLessonItem.style.height = '79px';
            blockLessonItemOutter.style.height = '80px';
        }
    }

    private onCoachClick(e: any, coach: StM.ICoachStoreState): void {
        if (e) { e.stopPropagation(); e.preventDefault(); }
        const coachCount = this.state.coachCount;
        this.props.onTabClickHandler({
            sessionType: 'lesson',
            lessonSubfilterId: coach.id,
            date: this.props.routeParams.date
        });
        const blockLessonItem: any = document.getElementsByClassName('side-block-item-lesson')[0];
        const blockLessonItemOutter: any = document.getElementsByClassName('side-block-item-wrapper-lesson')[0];
        blockLessonItem.style.height = this.coachHeight * coachCount + this.out + 'px';
        blockLessonItemOutter.style.height = this.coachHeight * coachCount + this.out + 'px';
    }

    private getRoute(coachId: string = null) {
        return this.routeSrv.getBookPage({
            ...this.props.routeParams,
            sessionType: StM.BookPageSessionType.Lesson,
            lessonSubfilterId: coachId,
            lessonSubfilter: coachId ? 'coach' : ''
        }).path;
    }
}